export default [
  {
    text: "Профиль",
    link: { name: "HousingStockProfile" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Общественная деятельность",
    link: { name: "HousingStockSocialActivity" },
    key: "social-activity",
    disabled: false,
  },
  {
    text: "Трудовая деятельность",
    link: { name: "HousingStockLaborActivity" },
    key: "labor-activity",
    disabled: false,
  },
  {
    text: "Персональные данные",
    link: { name: "HousingStockPersonalData" },
    key: "personal",
    disabled: false,
  },
  {
    text: "Документы",
    link: { name: "HousingStockDocs" },
    key: "docs",
    disabled: false,
  },
];
